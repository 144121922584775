.styled-paper {
  width: 93%;
  padding: 15px 25px 25px 25px;
  background: #FFFFFF;
  opacity: 1;
  margin: 10px auto 70px auto;
  border: 1px solid #DDDDDD;
}

.tabs-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #1a4980;
  margin-bottom: 0;
}

.custom-tabs {
  min-height: 40px;
  flex: 1;
}

.custom-tabs .MuiTabs-flexContainer {
  display: flex;
  justify-content: flex-start;
}

.custom-tab {
  text-transform: none !important;
  font-size: 13.5px !important;
  font-family: var(--font-medium) !important;
}

.custom-tab.Mui-selected {
  font-weight: bold !important;
  color: black !important;
}

.table-container {
  margin-top: 20px;
}

.styled-table-cell-header {
  background-color: #0f3b68;
  padding: 16px;
  font-size: 14px;
  font-family: var(--font-bold);
  color: white;
  border-bottom: none;
  text-align: left;
}

.styled-table-cell {
  padding: 16px;
  font-size: 14px;
  font-family: var(--font-medium);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
}

/* Add this new class for the div inside table cells */
.styled-table-cell div {
  text-align: left;
  margin: 0;
}

.filter-container {
  padding: 20px;
  margin-bottom: 0;
}

.select-field {
  border-radius: 22px !important;
  height: 35px;
}

.styled-menu-item {
  font-size: 14px !important;
  font-family: var(--font-medium) !important;
}

/* Loading state */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/* No data state */
.no-data-cell {
  text-align: center;
  padding: 20px;
}
