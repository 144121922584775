.create_IDSR_wrapper {
  margin: 0px;

  .project_wrapper {
    height: auto;
    padding: 0px 5px;
    margin-top: -15px;

    .delete_wrapper {
      margin-top: 20px;
      margin-left: 0px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      column-gap: 10px;
      align-items: center;
    }
  }
 
  .idsr_heading_box {
    margin-left: 16px;
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    margin-bottom: 5px;
    column-gap: 50px;

    .delete_icon {
      cursor: pointer;
    }
  }

  .add_delete_button_wrapper {
    display: flex;
    column-gap: 20px;
    justify-content: flex-end;
    margin-bottom: -12px;
    margin-top: -12px;
  }

  .button_wrapper {
    display: flex;
    align-items: center;
    align-content: center;
    padding: 16px;
  }

  .divider_wrapper {
    width: 100%;
    padding: 16px 0px 16px 16px;
  }
  .custom-ckeditor-wrapper {
    border-radius: 20px 20px 20px 20px;  }

  .custom_quill {
    .ql-toolbar.ql-snow {
      border-radius: 20px 20px 0px 0px;
    }

    .ql-container.ql-snow {
      border-radius: 0px 0px 20px 20px;
    }
  }

  .custom_ck {
    height: auto;
  .ck-toolbar_grouping{
      border-top-left-radius: 20px !important;
      border-top-right-radius: 20px !important;
    }
  
  .ck-editor__editable_inline:not(.ck-comment__input *) {
    height: auto;
    min-height: 80px;
    border-radius: 0px 0px 20px 20px;
    overflow-y: auto;
  }
  
}
}

.custom-tooltip {
  background-color: #ffffff !important;
  color: #000000 !important;
  max-width: 500px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1) !important;
}

.custom-tooltip-arrow::before {
  background-color: #ffffff !important;
}